import React from "react"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Markdown from "react-markdown"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import { XCircle, PlayCircleFill } from "react-bootstrap-icons"
import Grid from "@material-ui/core/Grid"
import bannerLogo from "../../assets/carebits-movie-banner.png"
import Fade from "react-reveal/Fade"
import { DialogContent } from "@material-ui/core"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CarebitsMovieBanner = ({ cms }) => {
  const [openMovie, setOpenMovie] = React.useState(false)

  const handleClickOpen = () => {
    setOpenMovie(true)
  }
  const handleClose = () => {
    setOpenMovie(false)
  }

  return (
    <>
      <Box
        className="carebits-movie-banner"
        component="section"
        display="flex"
        alignItems="center"
      >
        <Container>
          <Grid container>
            <Grid item className="carebits-movie-banner__left" xs={12} lg={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <div>
                  <Markdown allowDangerousHtml>{cms.title}</Markdown>
                  <Markdown allowDangerousHtml>{cms.content}</Markdown>
                </div>
              </Box>
            </Grid>
            <Grid
              item
              className="carebits-movie-banner__right"
              xs={12}
              lg={6}
              onClick={handleClickOpen}
            >
              <Fade fraction={0.5}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <img src={bannerLogo} alt="Pregnant woman"></img>

                  <PlayCircleFill
                    className="carebits-movie-banner__play-button"
                    size={64}
                  />
                </Box>{" "}
              </Fade>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Dialog
        className="carebits-movie-dialog"
        fullScreen
        open={openMovie}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent className="carebits-movie-dialog__content">
          <video
            poster={bannerLogo}
            preload="none"
            autoplay="autoplay"
            controls
          >
            <source src={cms.media.localFile.publicURL} type="video/mp4" />
          </video>
          <XCircle
            className="carebits-movie-dialog__close-button"
            onClick={handleClose}
            size={24}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CarebitsMovieBanner
