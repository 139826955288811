import React from "react"
import PropTypes from "prop-types"
import { CheckCircleFill } from "react-bootstrap-icons"
import CircularNavButton from "./circularNavButton"

import Box from "@material-ui/core/Box"

const CardAdvantages = ({ text }) => {
  return (
    <Box
      className="advantages-card"
      flexGrow={1}
      flexBasis={"20%"}
      px={3}
      py={5}
      mx={2}
      my={3}
    >
      <CheckCircleFill className="advantages-card__check-icon"></CheckCircleFill>
      <p>{text}</p>
    </Box>
  )
}

CardAdvantages.propTypes = {
  text: PropTypes.string,
}

CardAdvantages.defaultProps = {
  text: ``,
}

const CardAdvantagesLink = ({ text, link }) => {
  return (
    <Box
      className="advantages-card-link"
      flexGrow={1}
      flexBasis={"20%"}
      px={3}
      py={5}
      mx={2}
      my={3}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularNavButton
        text={text}
        className="white"
        link={link}
      ></CircularNavButton>
    </Box>
  )
}

CardAdvantagesLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

CardAdvantagesLink.defaultProps = {
  text: ``,
  link: "/",
}

export default CardAdvantages
export { CardAdvantagesLink }
