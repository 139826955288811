import React from "react"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Markdown from "react-markdown"

const HowItWorksHomeBanner = ({ cms }) => {
  return (
    <>
      <Box
        className="how-it-works-home-banner"
        component="section"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Container>
          <div className="how-it-works-home-banner__left">
            <Markdown allowDangerousHtml>{cms.title}</Markdown>
            <Markdown allowDangerousHtml>{cms.content}</Markdown>
          </div>
        </Container>
      </Box>
    </>
  )
}

export default HowItWorksHomeBanner
