import React from "react"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import Fade from "react-reveal/Fade"
import bannerLogo from "../../assets/what-ktg-is-banner.svg"

import Markdown from "react-markdown"

const WhatKtgIsBanner = ({ cms }) => {
  return (
    <Box
      className="what-ktg-is-banner"
      component="section"
      display="flex"
      alignItems="center"
    >
      <Container>
        <Grid container>
          <Grid item className="what-ktg-is-banner__left" xs={12} lg={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <div>
                <Markdown allowDangerousHtml>{cms.title}</Markdown>
                <Markdown allowDangerousHtml>{cms.content}</Markdown>
              </div>
            </Box>
          </Grid>
          <Grid item className="what-ktg-is-banner__right" xs={12} lg={6}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Fade fraction={0.5}>
                <img src={bannerLogo} alt="Ipad with carebits app view"></img>
              </Fade>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default WhatKtgIsBanner
