import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Markdown from "react-markdown"

const ForWhoCarebitsIsBanner = ({ cms }) => {
  return (
    <Box
      className="for-who-carebits-is-banner"
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Container>
        <div className="for-who-carebits-is-banner__left">
          <Markdown allowDangerousHtml>{cms.title}</Markdown>
          <Markdown allowDangerousHtml>{cms.content}</Markdown>
        </div>
      </Container>
    </Box>
  )
}

export default ForWhoCarebitsIsBanner
