import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MainMarketingBanner from "../sections/main-marketing-banner"
import WhatCarebitsIsBanner from "../sections/how-it-works/what-carebits-is-banner"
import HowItWorksHomeBanner from "../sections/how-it-works/how-it-works-home-banner"
import WhatKtgIsBanner from "../sections/how-it-works/what-ktg-is-banner"
import HowCarebitsWorksBanner from "../sections/how-it-works/how-carebits-works-banner"
import ForWhoCarebitsIsBanner from "../sections/how-it-works/for-who-carebits-is-banner"
import WhyTrustUsBanner from "../sections/how-it-works/why-trust-us-banner"
import CarebitsAdvantagesBanner from "../sections/how-it-works/carebits-advanteges-banner"

import { useIntl } from "gatsby-plugin-react-intl"
import CarebitsMovieBanner from "../sections/how-it-works/carebits-movie-banner"

const HowItWorksPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout footerCms={data.strapiFooter}>
      <SEO
        lang={intl.locale}
        title={data.strapiHowItWorks.seo.title}
        description={data.strapiHowItWorks.seo.description}
        keywords={data.strapiHowItWorks.seo.keywords}
      />
      <HowItWorksHomeBanner
        cms={data.strapiHowItWorks.howItWorksHomeBanner}
      ></HowItWorksHomeBanner>
      <CarebitsMovieBanner
        cms={data.strapiHowItWorks.howItWorksMovieBanner}
      ></CarebitsMovieBanner>
      <WhatCarebitsIsBanner
        cms={data.strapiHowItWorks.whatCarebitsIsBanner}
      ></WhatCarebitsIsBanner>
      <WhatKtgIsBanner
        cms={data.strapiHowItWorks.whatKtgIsBanner}
      ></WhatKtgIsBanner>
      <HowCarebitsWorksBanner
        cms={data.strapiHowItWorks.howCarebitsWorksBanner}
      ></HowCarebitsWorksBanner>
      <CarebitsAdvantagesBanner
        cms={data.strapiHowItWorks.carebitsAdvantagesBanner}
      ></CarebitsAdvantagesBanner>
      <ForWhoCarebitsIsBanner
        cms={data.strapiHowItWorks.forWhoCarebitsIsBanner}
      ></ForWhoCarebitsIsBanner>
      <WhyTrustUsBanner
        cms={data.strapiHowItWorks.whyTrustUsBanner}
      ></WhyTrustUsBanner>

      <MainMarketingBanner
        cms={data.strapiMain.marketingBanner}
      ></MainMarketingBanner>
    </Layout>
  )
}

export const query = graphql`
  query HowItWorks($language: String) {
    strapiHowItWorks(locale: { eq: $language }) {
      id
      seo {
        title
        description
        keywords {
          tag
        }
      }
      howItWorksHomeBanner {
        title
        content
      }
      howItWorksMovieBanner {
        title
        content
        media {
          localFile {
            publicURL
          }
        }
      }
      whatKtgIsBanner {
        title
        content
      }
      whatCarebitsIsBanner {
        title
        content
      }
      whatKtgIsBanner {
        title
        content
      }
      howCarebitsWorksBanner {
        title
        content
        accordion {
          title
          content
        }
      }
      carebitsAdvantagesBanner {
        title
        list {
          content
        }
        link_bar {
          box_title
          box_link
        }
      }
      forWhoCarebitsIsBanner {
        title
        content
      }
      whyTrustUsBanner {
        title
        list {
          content
          sub_content {
            content
          }
        }
      }
    }
    strapiMain(locale: { eq: $language }) {
      marketingBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
    }
    strapiFooter(locale: { eq: $language }) {
      company_name
      company_address
      call_center
      contact
      data_administrator
      rights_reserved
      page_links {
        title
        link
      }
      document_links {
        title
        link
      }
    }
  }
`

export default HowItWorksPage
